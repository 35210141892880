import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// JOURNEY IMAGES
import techwomen from "../images/journey/techwomen.jpg"
import activities from "../images/journey/activities.jpg"

// PARTNER IMAGES
import partner1 from "../images/partners/partner1.jpg"
import partner2 from "../images/partners/partner2.jpg"
import partner3 from "../images/partners/partner3.png"
import partner4 from "../images/partners/partner4.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="banner">
      <div className="banner__text">
        KasiMaths is a fun and interactive after-school Maths Enrichment Hub
      </div>


      <div className="banner__sub-text">
        We remind students that Maths is Fun, Maths is Essential and Maths is Everywhere !
      </div>
    </div>

    <div className="container goals__container">
      <h1 className="container__header"> Our Goals </h1>
      <div className="goal">
        KasiMaths is designed to help students from underprivileged township schools to <span className="goal__emphasis">develop mathematical skills</span>, <span className="goal__emphasis">gain confidence in their mathematical abilities</span>, <span className="goal__emphasis">increase their enjoyment of mathematics</span> and in the long-term, help students to pass mathematics at matric level and take up STEM courses at tertiary level.
      </div>
    </div>

    <div className="container journey__container">
      <h1 className="container__header"> Our Story </h1>

      <div className="journey__step">
        <div className="journey-step__images">
          <img className="journey-step__image" src={techwomen} alt="Techwomen"/>
          <img className="journey-step__image" src={activities} alt="Activities"/>
        </div>

        <div className="journey-step__text">
          <p>
            Kasi Maths was born in San Francisco out of the US Exchange Program called Tech Women in 2017. The South African Kasi Maths team won a grant to start the program, their main aim was to solve a socio economic problem within their home country, South Africa. Watch their pitch video here.
          </p>

          <p>
            In January 2018, The Kasi Maths team partnered with the US Embassy and Tlhatlogang School in Soweto, to give life to their vision and create their first enrichment hub at the Rosa Parks Library.
          </p>
          <p>
            The Kasi Maths Program had an average of 15-20 Grade 8 students attending every Monday and Wednesdays after school. During lessons, we help with homework, teach mathematics, explore electronics, robotics and coding, introduce 3D printing, and experiment with science and chemistry. We carry out all of these activities in a fun and exciting way to engage the students, and help them understand how mathematics is used in the real world.
          </p>

          <p>
            In 2019, our Grade 9 class is a group of intelligent young future STEM leaders. We would like to expand and have an impact on even more students around South Africa.
          </p>
        </div>
      </div>
    </div>

    <div className="container partners__container">
      <h1 className="container__header"> Our Partners & Sponsors </h1>

      <div className="partner__logos">
        <div className="partner__logo">
          <img src={partner1} alt="Partner"/>
        </div>
        <div className="partner__logo">
          <img src={partner2} alt="Partner"/>
        </div>
        <div className="partner__logo">
          <img src={partner3} alt="Partner"/>
        </div>
        <div className="partner__logo">
          <img src={partner4} alt="Partner"/>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
